.modal-slider {
  display: flex;
  flex-direction: column;
  background: #fff;
  height: 100%;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  will-change: transform;
  position: relative; }
  .modal-slider:focus {
    outline-style: none; }
  .modal-slider--right {
    margin-left: auto;
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
    .modal-slider--right.ReactModal__Content--after-open {
      -webkit-transform: translateX(0%);
              transform: translateX(0%); }
    .modal-slider--right.ReactModal__Content--before-close {
      -webkit-transform: translateX(100%);
              transform: translateX(100%); }
  .modal-slider--left {
    margin-right: auto;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
    .modal-slider--left.ReactModal__Content--after-open {
      -webkit-transform: translateX(0%);
              transform: translateX(0%); }
    .modal-slider--left.ReactModal__Content--before-close {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%); }
  .modal-slider--overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px; }
  .modal-slider--overlay.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s; }
  .modal-slider--overlay.ReactModal__Overlay--before-close {
    background-color: rgba(0, 0, 0, 0); }

